<template>
  <div>
    <p>{{mensaje}}</p>
  </div>
</template>

<script>
export default {
  name: 'notification-template',
  data () {
    return {
      mensaje: ''
    }
  }

}
</script>
