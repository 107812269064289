const handleError = {}

handleError.errorApi = function (err) {
  let errorMensaje
  if (!err.response) {
    errorMensaje = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
  } else {
    if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
      errorMensaje = err.response.data.errorMessage
    } else {
      switch (err.response.status) {
        case 500:
          errorMensaje = 'El servidor no se encuentra disponible.'
          break
        case 400:
          errorMensaje = err.response.data
          break
        /* default:
          /*si obtengo un system.exception, hago un split para poder obtener la primer parte
          *luego, de la primer parte, con un substr para extraer lo que sigue de
          *"system.exception" (17 caracteres) cerrar comentario

          let error=[]
          error =(err.response.data.split("at"))
          errorMensaje =  error[0].substr(18); */
      }
    }
  }
  return errorMensaje
}

export default handleError
