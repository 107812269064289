import api from './api'
const spotService = {}
// no le paso el codigo de cliente porque lo obtengo en la api
spotService.getSpotsBycodCliente = async function () {
  return api.get('spot/Cliente/')
    .then(res => localStorage.SpotDisponibles = JSON.stringify(res.data))
}

spotService.getSpots = async function () {
  return api.get('/spot/')
    .then(res => res.data)
}

spotService.guardarSpot = async function (nombreSpot, source, fechaDesde, fechaHasta, tipoSpot) {
  return api.post('/spot/', { nombreSpot, source, fechaDesde, fechaHasta, tipoSpot }
  ).then(res => res.data)
}

spotService.guardarSpotOperador = async function (nombreSpot, source, fechaDesde, fechaHasta, tipoSpot, cliente, clienteCodigo, locutor, codPedido) {
  return api.post('/spot/operador', { nombreSpot, source, fechaDesde, fechaHasta, tipoSpot, cliente, clienteCodigo, locutor, codPedido }
  ).then(res => res.data)
}

spotService.uploadSpot = async function (formData) {
  return api.post('/spot/upload/', formData
  ).then(res => res.data)
}

spotService.uploadNoticias = async function (formData) {
  return api.post(`/spot/uploadNoti`, formData
  ).then(res => res.data)
}

spotService.uploadSpotOperador = async function (formData, idCliente) {
  return api.post(`/spot/upload/${idCliente}`, formData
  ).then(res => res.data)
}

spotService.cambiarVencimiento = async function (codigoSpot, fecha) {
  return api.put('/spot/', { codigoSpot, fecha }
  ).then(res => res.data)
}

spotService.editarSpot = async function (codigoSpot, nombreSpot, source ,fechaInicio, fechaFin, tipo) {
  return api.put('/spot/editar', { codigoSpot, nombreSpot,source ,fechaInicio, fechaFin, tipo }
  ).then(res => res.data)
}
spotService.editarNoti = async function (codigoSpot, nombreSpot, source, fechaFin) {
  return api.put('/spot/editarNoti', { codigoSpot, nombreSpot, source, fechaFin }
  ).then(res => res.data)
}

spotService.bajaSpot = async function (codigoSpot, path) {
  return api.delete(`/spot/del/${codigoSpot}` + '/' + `${path}`
  ).then(res => res.data)
}

// Programacion spot
spotService.getProgramacionesByCliente = async function () {
  return api.get('/spot/programacion/').then(res => res.data)
}
// Programacion spot
spotService.ReemplazarPautados = async function (codigoSpotIn,codigoSpot,codigoProg=0) {
  return api.delete(`/spot/pautados/${codigoSpotIn}/${codigoSpot}/${codigoProg}`).then(res => res.data)
}
spotService.BorrarPautados = async function (codigoSpot,codigoProg=0) {
  return api.delete(`/spot/pautados/${codigoSpot}/${codigoProg}`).then(res => res.data)
}

// Programacion spot
spotService.getLinkShare = async function (codigoSpot) {
  return api.get(`/spot/getlinkdbx/${codigoSpot}`).then(res => res.data)
}

spotService.altaProgramacion = async function (codigoProgramacion, nombreProgramacion) {
  return api.post('/spot/programacion/', {

    codigoProgramacion, nombreProgramacion

  }).then(res => res.data)
}

spotService.bajaProgramacion = async function (codigoProgramacion) {
  return api.delete(`spot/programacion/${codigoProgramacion}`
  ).then(res => res.data)
}

export default spotService
