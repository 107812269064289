import api from './api'

const sucursalService = {}

const clienteSucursal = {
  clisuc_codigo: 0,
  clisuc_numeroSucursal: 0,
  clisuc_nombre: '',
  clisuc_fechaAlta: '',
  clisuc_idSucursal: 0,
  cli_codigo: 0,
  cli_usuari: '',
  username: '',
  password: '',
  adm_NroIntentos: 0,
  adm_fechaDesde: '',
  sucpgr_codigoSucursal: 0,
  clipro_nombre: ''
}

sucursalService.cliSucursal = clienteSucursal

sucursalService.get = function () {
  return clienteSucursal
}

sucursalService.getcliSucursalByCliente = async function () {
  return api.get('/sucursal/').then(res => res.data)
}

// para obtener la programacion de spot de una sucursal
sucursalService.getprogSpotBySuc = async function (nombreSucursal) {
  return api.get(`sucursal/${nombreSucursal}`).then(res => res.data)
}

sucursalService.altaSucursal = async function (idSucursal, nombreSucursal, nombreUsuarioSucursal,
  clientePrefijo, contrasenia, progRadioSelected, progSpotSelected, permisoSelected) {
  return api.post('sucursal/', {

    idSucursal,
    nombreSucursal,
    nombreUsuarioSucursal,
    clientePrefijo,
    contrasenia,
    progRadioSelected,
    progSpotSelected,
    permisoSelected

  }).then(res => res.data)
}

//* **FIX*** agregar una funcion editarSucursal que llame a .put('sucursal/')

sucursalService.bajaSucursal = async function (codigoSucursal, usernameSucursal) {
  return api.delete(`sucursal/${codigoSucursal}` + '/' + `${usernameSucursal}`
  ).then(res => res.data)
}

sucursalService.put = async function (idSucursal, nombreSucursal, nombreUsuarioSucursal, contrasenia) {
  return api.put('sucursal/', {

    idSucursal, nombreSucursal, nombreUsuarioSucursal, contrasenia

  }).then(res => res.data)
}

sucursalService.estadoSucursal = async function (nombreUsuarioSucursal) {
  return api.post('sucursal/estado', {

    nombreUsuarioSucursal

  }).then(res => res.data)
}

export default sucursalService
