import api from './api'

const facturaService = {}

facturaService.getFacturasByCliente = async function () {
  return api.get('/factura/Cliente/').then(res => res.data)
}

facturaService.getUltimaFactura = async function () {
  return api.get('/factura/Cliente/').then(res => res.data[0])
}

facturaService.verFactura = async function (codFactura) {
  return api.get(`/factura/${codFactura}`, {
    responseType: 'arraybuffer'
  }).then(res => res.data)
}

export default facturaService
